<template>
  <div>
    <div class="title">
      <div>
        <span>测评ID</span>
        <el-input
          class="input-width"
          size="small"
          v-model="state.id"
          placeholder="请输入测评ID"
        />
      </div>
       <div>
        <span>渠道ID</span>
        <el-input
          class="input-width"
          size="small"
          v-model="state.channelId"
          placeholder="请输入渠道ID"
        />
      </div>

      <div>
        <span>测评名称</span>
        <el-input
          class="input-width"
          size="small"
          v-model="state.name"
          placeholder="模糊匹配"
        />
      </div>
     
      <div>
        <span>测评时间</span>
        <el-date-picker
          class="input-width"
          size="small"
          v-model="state.time"
          type="date"
          placeholder="请选择时间"
        >
        </el-date-picker>
      </div>
      <div class="end-box">
        <el-button size="small" type="primary" @click="find">查询</el-button>
        <el-button size="small" @click="init">重置</el-button>
        
      </div>
    </div>
    <div class="main">
      <el-table
        :data="state.list"
        style="width: 100%"
        size="small"
        :header-cell-style="{ background: '#f7fbff', color: '#000000d9' }"
        border
        highlight-current-row
      >
        <el-table-column prop="subjectId" label="测评ID" align="center" />
        <el-table-column prop="title" label="测评名称" align="center" />
        <el-table-column prop="testNums" label="测评记录数" align="center" />
        <el-table-column prop="avgScore" label="评测平均分" align="center" >
          <template v-slot="scope">
            {{ scope.row.avgScore && scope.row.avgScore.toFixed(1)}}
          </template>
        </el-table-column>
        <el-table-column label="目前最高分与最低分" align="center" >
            <template v-slot="scope">
            {{ scope.row.maxScore+","+ scope.row.minScore}}
          </template>
        </el-table-column>
        <el-table-column prop="avgTime" label="平均做题时间" align="center" />
        <el-table-column prop="curNums" label="今日新增" align="center" />


      </el-table>
      <el-pagination
        class="pagination"
        v-model:currentPage="state.currentPage"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="state.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="state.total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { reactive, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { CODE } from "@/config"
export default {
  setup() {
    const state = reactive({
      id: "",
      name: "",
      time: "",
      channelId: "",
      currentPage:1,
      list: [],
      page: 1,
      size: 10,
      total: 0,
    });
    const init = () => {
      state.id = "";
      state.name = "";
      state.time = "";
      state.channelId = "";
    };
    const store = useStore();
    const getData = (v) => {
      let data
      if (v) {
        data = v
      }else{
        data = {
          page: state.page,
          size: state.size,
        };
      }
      
      store.dispatch("record/orderstatisList", data).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          console.log(res.data.values);
          state.list = res.data.values;
          if (res.data.total) {
            state.total = res.data.total * 1;
          }else{
            state.total = 0
          }
        }
      });
    };


    onBeforeMount(() => {
        getData();
    });

    const find = (i) => {
      if (i != 1) {
        state.page = 1
        state.currentPage = 1
      }
      const { id, name, time, channelId, page, size } = state;
      let data = {
        page,
        size,
      };
      if(id) data.id = id
      if(name) data.title = name
      if(time) data.gmtCreate = formatDate2(time)
      if(time) data.gmtEnd = formatDate2(time)
      if(channelId) data.channelId = channelId
      console.log(data);
      getData(data)
    };
    const handleCurrentChange = (v) => {
      state.page = v;
      find(1)
    };
    const handleSizeChange = (val) => {
      state.size = val
      getData();
      console.log(val)
    }
    const formatDate2 = function (value, spe = "-") {
      if (!value) {
        return;
      }
      let data = new Date(value * 1);
      let year = data.getFullYear();
      let month = data.getMonth() + 1;
      let day = data.getDate();
      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      return `${year}${spe}${month}${spe}${day}`;
    };
    return {
      state,
      init,
      find,
      handleCurrentChange,
      handleSizeChange,
      formatDate2
    };
  },
};
</script>

<style lang="scss">
.pagination{
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
* {
  font-size: 14px;
}
.main {
  background: white;
  padding: 24px;
}
.title {
  background: white;
  padding: 24px;
  .input-width {
    width: 202px;
  }
  div {
    display: flex;
    margin-right: 25px;
    align-items: center;
    display: inline-block;
    margin-bottom: 10px;
    & > span {
      display: inline-block;
      margin-right: 12px;
    }
  }
  .end-box {
    margin-left: 10px;
  }
  margin-bottom: 12px;
}
</style>
